import axios from "axios";
import Cookies from "js-cookie";

const jwtToken = Cookies.get("_auth");
const headers = {
  "X-AUTH-TOKEN": jwtToken,
  "Content-Type": "application/json",
};

const apiKey = process.env.REACT_APP_URL_API;
const fetchRowsApi = async (url) => {
  // const axiosUrl = apiKey + url;
  const axiosUrl = url;
  try {
    const response = await axios.get(axiosUrl, {
      headers: headers,
    });

    return {
      data: response.data.results.data.rows,
      isLoading: false,
      isError: false,
    };
  } catch (error) {
    console.error("Error:", error);
    return {
      data: null,
      isLoading: false,
      isError: true,
    };
  }
};

export default fetchRowsApi;
