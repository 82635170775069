import React from "react";
import { Input, Label, Media } from "reactstrap";
import { areYouSureAlert } from "../Alerts/Alerts";

const ChangeStateCheckbox = ({ state, changeActiveState }) => {
  const changeState = () => {
    areYouSureAlert().then((result) => {
      if (result.isConfirmed) {
        changeActiveState(state);
      }
    });
  };
  return (
    <Media body className="text-end icon-state">
      <Label className="switch">
        <Input
          type="checkbox"
          onClick={changeState}
          defaultChecked={state.state}
        />
        <span className="switch-state"></span>
      </Label>
    </Media>
  );
};

export default ChangeStateCheckbox;
