import React from "react";
import { Trash } from "react-feather";

const DeleteButton = (editElement) => {
  const renderCell = (code) => (
    <button
      className="btn btn-danger btn-sm"
      color="error"
      onClick={() => editElement(code)}>
      <Trash />
    </button>
  );

  return {
    name: "Delete",
    cell: (row) => renderCell(row),
    center: "true",
  };
};

export default DeleteButton;
