import React, { Fragment } from "react";
import { Col, FormGroup, Input, Label, Media } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";

const CheckBox = ({ register, inputName, oldValue }) => {
  const { name, title } = inputName;
  const [state, setState] = useState({
    name: name,
    value: oldValue ? true : false,
  });

  useEffect(() => {
    register(state);
  }, [state]);

  const changeState = () => {
    const newState = { name: name, value: !state.value };
    setState(newState);
    register(newState);
  };

  return (
    <Fragment>
      <Col sm="1">
        <FormGroup>
          <Label>{title}</Label>
          <Media body className="text-end icon-state">
            <Label className="switch">
              <Input
                type="checkbox"
                onClick={changeState}
                defaultChecked={state.value}
              />
              <span className="switch-state"></span>
            </Label>
          </Media>
        </FormGroup>
      </Col>
    </Fragment>
  );
};

export default CheckBox;
