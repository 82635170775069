import axios from "axios";
// import { env } from 'process';
import Cookies from "js-cookie";
const proxyLink = process.env.REACT_APP_URL_API;
const jwtToken = Cookies.get("_auth");
const headers = {
  "X-AUTH-TOKEN": jwtToken,
  "Content-Type": "application/json",
};
const axiosClient = axios.create({
  // baseURL: process.env.REACT_APP_URL_API,
  headers: headers,
});

export default axiosClient;
