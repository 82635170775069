import React, { useState } from "react";
import { Fragment } from "react";
import Select from "react-select";
import { Col, FormGroup, Input, Label, Media } from "reactstrap";

const SelectInput = ({ register, inputName }) => {
  const { name, title, options } = inputName;
  return (
    <Fragment>
      <Col sm="4">
        <FormGroup>
          <Label>{title}</Label>
          <Select
            onChange={register}
            options={options}
            name={name}
            className="js-example-basic-single col-sm-12"
          />
        </FormGroup>
      </Col>
    </Fragment>
  );
};
export default SelectInput;
