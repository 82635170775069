import axiosClient from "../../Services/axios";
import { addAlert, missingAlert } from "../Alerts/Alerts";

const postData = (new_data, URL_CREATE) => {
  if ("password" in new_data) {
    delete new_data.password;
  }
  if ("confirmpassword" in new_data) {
    delete new_data.confirmpassword;
  }
  console.log("URL_CREATE:", URL_CREATE);
  console.log("new_data:", new_data);
  axiosClient
    .post(URL_CREATE, new_data)
    .then((res) => {
      addAlert();
    })
    .catch((error) => {
      console.error("Request failed:", error);
      missingAlert();
    });
};
export default postData;
