// Urls
export const URL_PURCHASEORDERS = {
  GET_PURCHASEORDERS: "/distributor/purchaseorders",
  CREATE_PURCHASEORDERS: "/distributor/purchaseorder",
};

// Columns
export const PURCHASEORDERS_COLUMNS = [
  {
    name: "sender_informations",
    selector: "sender_informations",
  },
  {
    name: "agency",
    selector: "agency",
  },
  {
    name: "status_label",
    selector: "status_label",
  },
  {
    name: "created_at",
    selector: "created_at",
  },
];

// Search
export const PURCHASEORDERS_SEARCH_ITEMS = [
  {
    title: "Sender Informations",
    type: "text",
    placeholder: "Sender Informations",
    name: "sender_informations",
    inputForm: "TitleInput",
  },
  {
    title: "Agency",
    type: "text",
    placeholder: "Agency",
    name: "agency",
    inputForm: "TitleInput",
  },
  {
    title: "Status Label",
    type: "text",
    placeholder: "Status Label",
    name: "status_label",
    inputForm: "TitleInput",
  },
  {
    title: "Created At",
    type: "text",
    placeholder: "Created At",
    name: "created_at",
    inputForm: "TitleInput",
  },
];

// Form
export const PURCHASEORDERS_ADD = [
  {
    title: "First name",
    type: "text",
    placeholder: "First name",
    name: "firstname",
    inputForm: "TitleInput",
  },
  {
    title: "Last name",
    type: "text",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "TitleInput",
  },
  {
    title: "Phone",
    type: "text",
    placeholder: "Phone",
    name: "phone",
    inputForm: "TitleInput",
  },
  {
    title: "Address",
    type: "text",
    placeholder: "Address",
    name: "address",
    inputForm: "TitleInput",
  },
  {
    title: "City",
    type: "text",
    placeholder: "City",
    name: "city",
    inputForm: "TitleInput",
  },
  {
    title: "Agency",
    type: "text",
    placeholder: "Agency",
    name: "agency",
    inputForm: "TitleInput",
  },
  {
    title: "Civility",
    type: "text",
    placeholder: "Civility",
    name: "civility",
    inputForm: "TitleInput",
  },
  {
    title: "Products",
    type: "text",
    placeholder: "Products",
    name: "products",
    inputForm: "TitleInput",
  },
];

export const PURCHASEORDERS_EDIT = [
  {
    title: "First name",
    type: "text",
    placeholder: "First name",
    name: "firstname",
    inputForm: "TitleInput",
  },
  {
    title: "Last name",
    type: "text",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "TitleInput",
  },
  {
    title: "Phone",
    type: "text",
    placeholder: "Phone",
    name: "phone",
    inputForm: "TitleInput",
  },
  {
    title: "Address",
    type: "text",
    placeholder: "Address",
    name: "address",
    inputForm: "TitleInput",
  },
  {
    title: "City",
    type: "text",
    placeholder: "City",
    name: "city",
    inputForm: "TitleInput",
  },
  {
    title: "Agency",
    type: "text",
    placeholder: "Agency",
    name: "agency",
    inputForm: "TitleInput",
  },
  {
    title: "Civility",
    type: "text",
    placeholder: "Civility",
    name: "civility",
    inputForm: "TitleInput",
  },
  {
    title: "Products",
    type: "text",
    placeholder: "Products",
    name: "products",
    inputForm: "TitleInput",
  },
];
