import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn } from "../../AbstractElements";

const ModalApp = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggler}
      size={props.size}
      centered>
      <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      {/* <ModalFooter>
        <Btn attrBtn={{ color: "secondary", onClick: props.toggler }}>
          Close
        </Btn>
        <Btn attrBtn={{ color: "success", onClick: props.toggler }}>Save</Btn>
      </ModalFooter> */}
    </Modal>
  );
};
export default ModalApp;
