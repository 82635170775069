import { useState, Fragment } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";

import { Btn } from "../../AbstractElements";
import FilterInput from "../Inputs";
import postData from "./postData";

const AddItem = ({ addURL, items }) => {
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    if (event.target === undefined) {
      setInputs((values) => ({ ...values, [event.name]: event.value }));
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const onSubmit = () => {
    postData(inputs, addURL);
  };

  return (
    <Fragment>
      <CardBody>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form className="theme-form">
                    <Row>
                      {items.map((item, index) => (
                        <FilterInput
                          key={index}
                          item={item}
                          handleChange={handleChange}
                          index={index}
                        />
                      ))}
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          <Btn
                            attrBtn={{
                              onClick: onSubmit,
                              color: "secondary",
                              className: "me-4",
                            }}>
                            Add
                          </Btn>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Fragment>
  );
};

export default AddItem;
