import React, { Fragment } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

const SimpleInput = ({ register, inputName, oldValue }) => {
  const { name, type, title, placeholder } = inputName;
  const defaultValue = oldValue;
  return (
    <Fragment>
      <Col sm="4">
        <FormGroup>
          <Label>{title}</Label>
          <input
            className="form-control"
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={register}
            defaultValue={defaultValue}
            required
          />
        </FormGroup>
      </Col>
    </Fragment>
  );
};

export default SimpleInput;
