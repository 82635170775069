import React, { Fragment } from "react";
import { Btn, H4, H6 } from "../../AbstractElements";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { useState } from "react";
import { useLogin } from "../../hooks/useLogin";
import Loading from "../../Components/Loading/Loading";

const LoginForm = () => {
  const { login, isLoading } = useLogin();
  const [inputs, setInputs] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSignIn = () => {
    if (!inputs.username || !inputs.password) return;
    login(inputs);
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  };

  const codeSubmited = () => {
    console.log("code subited successefully");
  };

  return (
    <Fragment>
      <div className="login-card">
        <Form className="theme-form login-form">
          <H4>Login</H4>
          <H6>Welcome back! Log in to your account.</H6>
          {isLoading ? (
            <div style={overlayStyle}>
              <Loading />
            </div>
          ) : (
            <FormGroup onSubmit={codeSubmited}>
              <Label>Email Address</Label>
              <div className="input-group">
                <span className="input-group-text">
                  <i className="icon-email"></i>
                </span>
                <Input
                  name="username"
                  onChange={handleChange}
                  className="form-control"
                  type="email"
                  required=""
                  placeholder="email@gmail.com"
                />
              </div>
              <Label>Password</Label>
              <div className="input-group">
                <span className="input-group-text">
                  <i className="icon-lock"></i>
                </span>
                <Input
                  className="form-control"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={handleChange}
                  required=""
                  placeholder="*********"
                />
                <div className="show-hide">
                  <span
                    className={showPassword ? "hide" : "show"}
                    onClick={() => setShowPassword(!showPassword)}></span>
                </div>
              </div>

              <div style={{ marginTop: "10%" }}>
                <Btn
                  attrBtn={{
                    name: "directionsdangerToast",
                    color: "secondary",
                    onClick: (e) => handleSignIn(e.target.name),
                  }}>
                  Sign in
                </Btn>
              </div>
            </FormGroup>
          )}
        </Form>
      </div>
    </Fragment>
  );
};

export default LoginForm;
