// Urls
export const URL_USER = {
  USERS_LIST: "/distributor/users",
  GET_USER: "/distributor/user",
  GET_USER_AGENCIES: "/distributor/user/",
  CREATE_USER: "/distributor/user",
};

// Columns
export const USERS_COLUMNS = [
  {
    name: "Civility",
    selector: "civility",
  },
  {
    name: "First name",
    selector: "firstname",
  },
  {
    name: "Last name",
    selector: "lastname",
  },
  {
    name: "Phone",
    selector: "phone",
  },
  { name: "Role", selector: "role" },
];

// Search
export const USERS_SEARCH_ITEMS = [
  {
    title: "Last name",
    type: "text",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    type: "mail",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone Number",
    type: "text",
    placeholder: "Phone Number",
    name: "phone",
    inputForm: "SimpleInput",
  },
  {
    title: "Civility",
    type: "text",
    placeholder: "Civility",
    name: "civility",
    inputForm: "SimpleInput",
  },
  {
    title: "Role",
    name: "role",
    inputForm: "SelectInputGroups",
  },
];

// Form
export const USERS_ADD = [
  {
    title: "First name",
    type: "text",
    placeholder: "First name",
    name: "firstname",
    inputForm: "TitleInput",
  },
  {
    title: "Civility",
    type: "text",
    placeholder: "Civility",
    name: "civility",
    inputForm: "TitleInput",
  },
  {
    title: "Last name",
    type: "text",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "TitleInput",
  },
  {
    title: "Phone",
    type: "text",
    placeholder: "Phone",
    name: "phone",
    inputForm: "TitleInput",
  },
  {
    title: "Address",
    type: "text",
    placeholder: "Address",
    name: "address",
    inputForm: "TitleInput",
  },
  {
    title: "City",
    type: "text",
    placeholder: "City",
    name: "city",
    inputForm: "TitleInput",
  },
  {
    title: "Zip Code",
    type: "text",
    placeholder: "Zip Code",
    name: "zip_code",
    inputForm: "TitleInput",
  },
];

export const USERS_EDIT = [
  {
    title: "First name",
    type: "text",
    placeholder: "First name",
    name: "firstname",
    inputForm: "TitleInput",
  },
  {
    title: "Civility",
    type: "text",
    placeholder: "Civility",
    name: "civility",
    inputForm: "TitleInput",
  },
  {
    title: "Last name",
    type: "text",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "TitleInput",
  },
  {
    title: "Phone",
    type: "text",
    placeholder: "Phone",
    name: "phone",
    inputForm: "TitleInput",
  },
  {
    title: "Address",
    type: "text",
    placeholder: "Address",
    name: "address",
    inputForm: "TitleInput",
  },
  {
    title: "City",
    type: "text",
    placeholder: "City",
    name: "city",
    inputForm: "TitleInput",
  },
  {
    title: "Zip Code",
    type: "text",
    placeholder: "Zip Code",
    name: "zip_code",
    inputForm: "TitleInput",
  },
];
