import React from "react";
import { Fragment } from "react";
import Select from "react-select";
import { Col, FormGroup, Label } from "reactstrap";
import SelectSpinner from "../SelectSpinner";
import FetchingData from "../../Services/FetchingData";
const SelectApiInput = ({ register, inputName, oldValue }) => {
  const { name, title, optionsApi } = inputName;

  const getOptions = (data) => {
    const options = data.map((group) => ({
      value: group.code,
      label: group.label,
      name: name,
    }));
    return options;
  };

  const { data, isLoading, isError } = FetchingData(optionsApi);
  let options = [];

  if (isError) return <h2>Error 404</h2>;
  if (!isLoading) options = getOptions(data.data.results.data);

  return (
    <Fragment>
      <Col sm="4">
        <FormGroup>
          <Label>{title}</Label>
          <Select
            className="js-example-basic-single col-sm-12"
            defaultInputValue={oldValue}
            onChange={register}
            options={options}
            name={name}
            components={
              isLoading
                ? {
                    DropdownIndicator: SelectSpinner,
                  }
                : {}
            }
          />
        </FormGroup>
      </Col>
    </Fragment>
  );
};
export default SelectApiInput;
