import React from "react";
import { Eye } from "react-feather";
import { Btn } from "../../AbstractElements";

const ReadButton = (editElement) => {
  const renderCell = (code) => (
    <div className="text-end">
      <Btn
        attrBtn={{
          onClick: () => editElement(code),
          color: "success",
          variant: "contained",
          className: "btn btn-success btn-sm",
        }}>
        <Eye />
      </Btn>
    </div>
  );

  return {
    name: "Read",
    cell: (row) => renderCell(row),
    center: "true",
  };
};

export default ReadButton;
