import EditButton from "../Components/OptionalButtons/EditButton";
import ReadButton from "../Components/OptionalButtons/ReadButton";
import DeleteButton from "../Components/OptionalButtons/DeleteButton";
import ChangeStateCheckbox from "../Components/OptionalButtons/ChangeStateCheckbox";
import SelectCheckbox from "../Components/OptionalButtons/SelectCheckbox";

export default function addOptionalButtons(
  new_columns,
  new_rows,
  optionalFunctions
) {
  return new Promise((resolve, reject) => {
    let filteredRows = new_rows;
    const { handleRead, handleEdit, handleDelete, changeActiveState } =
      optionalFunctions();

    const actions = [
      { name: "Read", action: handleRead, button: ReadButton },
      { name: "Edit", action: handleEdit, button: EditButton },
      { name: "Delete", action: handleDelete, button: DeleteButton },
    ];

    const filteredColumns = new_columns
      .filter((column) => !["Edit", "Read", "Delete"].includes(column.name))
      .concat(
        actions
          .filter(({ name }) =>
            new_columns.some((column) => column.name === name)
          )
          .map(({ action, button }) => button(action))
      );
    const hasActive = new_columns.some((item) => item.type === "toggleswitch");
    if (hasActive)
      filteredRows = new_rows.map((item) => ({
        ...item,
        active: (
          <ChangeStateCheckbox
            state={{ state: item.active, code: item.code }}
            changeActiveState={changeActiveState}
          />
        ),
      }));
    const hasCheckbox = new_columns.some((item) => item.type === "checkbox");
    // if (hasCheckbox)
    //   filteredRows = new_rows.map((item) => ({
    //     ...item,
    //     Select: (
    //       <SelectCheckbox
    //         state={{ state: item.active, code: item.code }}
    //         changeActiveState={changeActiveState}
    //       />
    //     ),
    //   }));
    resolve({ filteredColumns, filteredRows });
  });
}
