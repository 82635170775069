import fetchRowsApi from "./fetchRowsApi";

export const initialState = {
  rows: [],
  isLoading: true,
  isError: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        rows: action.payload,
        isLoading: false,
        isError: false,
      };
    case "FETCH_LOADING":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    default:
      return state;
  }
};

export const fetchRows = (searchLink, dispatch) => {
  dispatch({ type: "FETCH_LOADING" });
  fetchRowsApi(searchLink)
    .then((res) => {
      if (res.isLoading) dispatch({ type: "FETCH_LOADING" });
      else if (!res.isLoading && !res.isError)
        dispatch({ type: "FETCH_SUCCESS", payload: res.data });
      else {
        dispatch({ type: "FETCH_ERROR" });
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      dispatch({ type: "FETCH_ERROR" });
    });
};
