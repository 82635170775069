// Layout
export const DASHBOARD = "Dashboard";
export const USERS = "Users";
export const POLYGONS = "Polygons";
export const PROFILES = "Profiles";
export const BRANDS = "Brands";
export const SERVICES_LIST = "Services list";
export const HOLIDAYS = "Holidays";
export const TAXIMETERS_IN_PREPARATION = "Taximeters in Preparation";
export const TAXIMETERS_IN_STOCKS = "Taximeters in Stocks";
export const BOXES = "Boxes";
export const IMPRIMANTES = "Imprimantes";
export const LUMINOUS_IN_PREPARATION = "Luminous in Preparation";
export const BADGE = "Badge";
export const CLIENT = "Client";
export const LIST_OF_USERS = "List of users";
export const SEARCH = "Search";
export const FILTERS = "Filters";
export const LOADING = "Loading";
export const PARAMETER = "Parameter";
export const STOCK = "Stock";
export const COMMERCIAL = "Commercial";

// form
export const ADD_ITEM = "Add item";
export const EDIT_ITEM = "Edit item";
export const IMPORT_DATA = "Import data";

// User Page
export const USERS_LIST = "List of Users";
export const USERS_PAGE = "Users page";

// Polygone Page
export const POLYGONE = "Polygone";
export const POLYGONE_LIST = "List of Polygone";
export const POLYGONE_PAGE = "Polygone page";

// Profile Page
export const PROFILE = "Profile";
export const PROFILE_LIST = "List of Profile";
export const PROFILE_PAGE = "Profile page";

// Brand Page
export const BRAND = "Brand";
export const BRAND_LIST = "List of Brand";
export const BRAND_PAGE = "Brand page";

// Brand Page
export const SERVICES_LIST_LIST = "List of Services";
export const SERVICES_LIST_PAGE = "Services list page";

// ReadProfile
export const TARIFF = "Tariff";
export const FLATFEE = "Flatfee";

// ReadProfile
export const PARAMETER_LIST = "List of Parameter";

// ReadTariff
export const TARIFF_LIST = "List of Tariff";

// Taximete_in_Preparationr Page
export const TAXIMETERS_IN_PREPARATION_LIST =
  "List of Taximeters in Preparation";
export const TAXIMETERS_IN_PREPARATION_PAGE = "Taximeters in Preparation page";

// Taximeters in Stocks Page
export const TAXIMETERS_IN_STOCKS_LIST = "List of Taximeters in Stocks";
export const TAXIMETERS_IN_STOCKS_PAGE = "Taximeters in Stocks page";

// Luminous in Preparation Page
export const LUMINOUS_IN_PREPARATION_LIST = "List of Luminous in Preparation";
export const LUMINOUS_IN_PREPARATION_PAGE = "Luminous in Preparation page";

// Boxes Page
export const BOXES_LIST = "List of Boxes";
export const BOXES_PAGE = "Boxes page";

// Imprimantes Page
export const IMPRIMANTES_LIST = "List of Imprimantes";
export const IMPRIMANTES_PAGE = "Imprimantes page";

// Badge Page
export const BADGE_LIST = "List of Badge";
export const BADGE_PAGE = "Badge page";

// Client Page
export const CLIENT_LIST = "List of Client";
export const CLIENT_PAGE = "Client page";

// Sensor Page
export const SENSOR = "Sensor";
export const SENSOR_LIST = "List of Sensor";
export const SENSOR_PAGE = "Sensor page";
