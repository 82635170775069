import { Row } from "reactstrap";
import React from "react";

import SelectApiInput from "./SelectApiInput";
import SimpleInput from "./SimpleInput";
import SelectInput from "./SelectInput";
import TitleInput from "./TitleInput";
import CheckBox from "./CheckBox";

const FilterInput = ({ item, handleChange, oldValue }) => {
  switch (item.inputForm) {
    case "SimpleInput":
      return (
        <SimpleInput
          register={handleChange}
          inputName={item}
          oldValue={oldValue}
        />
      );
    case "TitleInput":
      return (
        <TitleInput
          register={handleChange}
          inputName={item}
          oldValue={oldValue}
        />
      );
    case "SelectInput":
      return (
        <SelectInput
          register={handleChange}
          inputName={item}
          oldValue={oldValue}
        />
      );
    case "CheckBox":
      return (
        <Row>
          <CheckBox
            register={handleChange}
            inputName={item}
            oldValue={oldValue}
          />
        </Row>
      );
    case "SelectApiInput":
      return (
        <Row>
          <SelectApiInput
            register={handleChange}
            inputName={item}
            oldValue={oldValue}
            optionsApi={item.optionsApi}
          />
        </Row>
      );
    default:
      return null;
  }
};

export default FilterInput;
