import Swal from "sweetalert2";

export const addAlert = () => {
  Swal.fire({
    position: "top-end",
    icon: "success",
    title: "Your user has been created",
    showConfirmButton: false,
    timer: 1500,
  });
};
export const missingAlert = () => {
  return Swal.fire("Missing Information", "Please fill in all fields", "error");
};

export const areYouSureAlert = () => {
  return Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, chnage it!",
  });
};

export const changedAlert = () => {
  Swal.fire({
    position: "top-end",
    icon: "success",
    title: "Your data has been changed",
    showConfirmButton: false,
    timer: 1500,
  });
};
