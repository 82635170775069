import { useQuery } from "react-query";
import axiosClient from "./axios";

const FetchingData = (url) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: url,
    queryFn: () => axiosClient.get(url),
    enabled: true,
  });
  return { data, isLoading, isError };
};
export default FetchingData;
