import axios from "axios";
import sha512 from "js-sha512";
// import { useSignIn } from 'react-auth-kit';

const apiKey = process.env.REACT_APP_URL_API;

export const login = async ({ username, password }) => {
  // const signIn = useSignIn();
  const url = apiKey + "/authenticate";
  const intention = "DISTRIBUTOR";
  // const hashPass = sha512(password);
  const hashPass =
    "82b71e9ab741e1f9fa5c68a641b769164da2385d04c18593680168d9789afcc6f2b9925eaaa6f841bcaec32f918e5cd5b6945305269ccb60fb30aa771bb96b27";
  const authData = {
    username: username,
    intention: intention,
    password: hashPass,
  };
  let result = null;
  try {
    result = await axios.post(url, authData);
  } catch (err) {
    console.error(err);
    result = err;
  }

  return result;
};
