import React from "react";
import { PlusCircle, RefreshCw, Link } from "react-feather";
import { Row, Col, Card, Nav } from "reactstrap";
import { Btn } from "../../AbstractElements";
const DataTableHeader = ({
  AddModaltoggle,
  refreshData,
  generateSerials,
  ImportModaltoggleData,
}) => {
  const changeState = () => {
    AddModaltoggle();
  };
  const refreshPage = () => {
    refreshData();
  };
  const showGenerateButton = generateSerials ? true : false;
  const showImportDataButton = ImportModaltoggleData ? true : false;
  return (
    <Col md="12" className="project-list">
      <Card>
        <Row>
          <Col sm="6">
            <Nav tabs className="border-tab">
              <div className="text-end">
                <Btn
                  attrBtn={{
                    onClick: changeState,
                    color: "success",
                    className: "btn btn-success btn-sm",
                  }}>
                  <PlusCircle /> Create
                </Btn>
              </div>

              <div className="text-end" style={{ marginLeft: "20px" }}>
                <Btn
                  attrBtn={{
                    onClick: refreshPage,
                    color: "info",
                    className: "btn btn-success btn-sm",
                  }}>
                  <RefreshCw /> Refresh
                </Btn>
              </div>

              {showGenerateButton && (
                <div className="text-end" style={{ marginLeft: "20px" }}>
                  <Btn
                    attrBtn={{
                      onClick: generateSerials,
                      color: "dark-dark",
                      className: "btn btn-dark btn-sm",
                    }}>
                    <Link /> QR Code
                  </Btn>
                </div>
              )}

              {showImportDataButton && (
                <div className="text-end" style={{ marginLeft: "20px" }}>
                  <Btn
                    attrBtn={{
                      onClick: ImportModaltoggleData,
                      color: "dark-dark",
                      className: "btn btn-dark btn-sm",
                    }}>
                    <Link /> import
                  </Btn>
                </div>
              )}
            </Nav>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default DataTableHeader;
