import Loader from "./Loader";
import Taptop from "./TapTop";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import { Fragment, useState } from "react";
import { Outlet } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const AppLayout = () => {
  const [toggleIcon, setToggleIcon] = useState(false);
  const [mixLayout, setMixLayout] = useState(false);
  const [sidebarResponsive, setSidebarResponsive] = useState(false);
  const [IsOpen, setIsClose] = useState(false);

  const toggleSidebar = (toggle) => {
    setToggleIcon(toggle);
  };

  const toggleSidebarResponsive = (toggle) => {
    setSidebarResponsive(toggle);
  };
  return (
    <Fragment>
      <Loader />
      <Taptop />
      <div className="page-wrapper compact-wrapper modern-sidebar">
        <Header
          toggleSidebar={toggleSidebar}
          toggleIcon={toggleIcon}
          setToggleIcon={setToggleIcon}
          toggleSidebarResponsive={toggleSidebarResponsive}
        />
        <div className="page-body-wrapper horizontal-menu">
          <Sidebar toggleIcon={toggleIcon} />
          <TransitionGroup>
            <CSSTransition timeout={100} classNames="fadeIn" unmountOnExit>
              <div className="page-body">
                <Outlet />
              </div>
            </CSSTransition>
          </TransitionGroup>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default AppLayout;
