import { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import addOptionalButtons from "../../Services/addOptionalButtons";
// import Loading from "../Loading/Loading";

const DataTables = ({ new_columns, new_rows, optionalFunctions }) => {
  const [isButtonAdded, setIsButtonAdded] = useState(false);
  const [columns, setColumns] = useState(transformColumns(new_columns));
  const [rows, setRows] = useState(new_rows);

  if (!isButtonAdded && optionalFunctions) {
    addOptionalButtons(columns, new_rows, optionalFunctions)
      .then((res) => {
        setColumns(res.filteredColumns);
        setRows(res.filteredRows);
        setIsButtonAdded(true);
      })
      .catch((error) => console.error(error));
  }
  // organizeData
  return (
    <Fragment>
      <Container fluid="true" className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  columns={columns}
                  data={rows}
                  striped="true"
                  center="true"
                  persistTableHead
                  pagination
                  paginationPerPage={5}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default DataTables;

function transformColumns(columns) {
  if (!columns) return [];
  return columns.map((column) => {
    const transformedColumn = {
      name: column.name,
      selector: (row) => row[column.selector],
      sortable: "true",
      center: "true",
      type: column.type ? column.type : "text",
    };
    return transformedColumn;
  });
}
