import UserPage from "../pages/UserPage";
import PurchaseOrderPage from "../pages/PurchaseOrderPage";
import AgenciesPage from "../pages/AgenciesPage";
import ClientPage from "../pages/ClientPage";

export const PAGE_ROUTES = [
  {
    path: "/users",
    element: <UserPage />,
  },
  {
    path: "/purchase-order",
    element: <PurchaseOrderPage />,
  },
  {
    path: "/agencies",
    element: <AgenciesPage />,
  },
  {
    path: "/Client",
    element: <ClientPage />,
  },
];
