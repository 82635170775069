import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container } from "reactstrap";

import { Error } from "./Error";
import { H3, P } from "../../AbstractElements";

const NotFound = ({ changePage }) => {
  let text;
  const navigate = useNavigate();
  const handleClick = () => {
    return changePage ? navigate("/login") : window.location.reload();
  };
  changePage
    ? (text = {
        sub_content:
          "The page you are attempting to reach is currently not available. This may be because the page does not exist or has been moved.",
        btn: "BACK TO HOME PAGE",
      })
    : (text = {
        sub_content:
          "The data you are attempting to reach is currently not available. This may be because the data does not exist or has been moved.",
        btn: "REFRESH PAGE",
      });
  return (
    <Fragment>
      <div className="page-wrapper" id="pageWrapper">
        <div className="error-wrapper">
          <Container>
            <div className="error-page1">
              <div className="svg-wrraper mb-0">
                <Error />
              </div>
              <Col md="8" className="offset-md-2">
                <H3>page not found</H3>
                <P attrPara={{ className: "sub-content" }}>
                  {text.sub_content}
                </P>
                <div className="btn btn-primary btn-lg" onClick={handleClick}>
                  {text.btn}
                </div>
              </Col>
            </div>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default NotFound;
