import React from "react";
import { Btn } from "../../AbstractElements";
import { Card } from "reactstrap";
import { Fragment } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Search } from "react-feather";
import SearchForm from "./SearchForm";

const FilterBar = ({ url, searchByItems, setSearchLink }) => {
  const [openSearchForm, setOpenSearchForm] = useState(false);

  const onClickOpenFilter = () => {
    setOpenSearchForm(!openSearchForm);
  };

  const onChangeSearchLink = (newSearchLink) => setSearchLink(newSearchLink);

  const style = {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    margin: "20px",
  };
  return (
    <>
      <div>
        <div className="product-grid">
          <Card>
            <div
              onClick={onClickOpenFilter}
              style={style}
              className="project-list">
              <h2>Search</h2>
              <Btn
                attrBtn={{
                  onClick: onClickOpenFilter,
                  color: "secondary",
                  className: "btn btn-dark-info btn",
                }}>
                <Search /> Search
              </Btn>
            </div>
            {openSearchForm && (
              <SearchForm
                url={url}
                searchByItems={searchByItems}
                onChangeSearchLink={onChangeSearchLink}
              />
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

export default FilterBar;
