import React, { useState } from "react";
import { Card, Col, FormGroup, Row } from "reactstrap";
import { Btn } from "../../AbstractElements";
import addSearchItems from "../../Services/addSearchItems";
import FilterInput from "../Inputs";
const SearchForm = ({ url, searchByItems, onChangeSearchLink }) => {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    if (event.target === undefined) {
      setInputs((values) => ({ ...values, [event.name]: event.value }));
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const onChangeForm = () => {
    console.log(inputs);
    const new_link = addSearchItems(url, inputs);
    console.log(new_link);
    onChangeSearchLink(new_link);
  };

  return (
    <div style={{ margin: "30px" }}>
      <Row>
        {searchByItems.map((item, index) => (
          <FilterInput
            key={index}
            item={item}
            handleChange={handleChange}
            index={index}
          />
        ))}
        <Row>
          <Col>
            <FormGroup className="mb-0">
              <Btn
                attrBtn={{
                  onClick: onChangeForm,
                  color: "secondary",
                  className: "me-4",
                }}>
                Search
              </Btn>
            </FormGroup>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default SearchForm;
