function addSearchItems(baseUrl, searchObject) {
  const queryParams = [];

  for (const key in searchObject) {
    if (searchObject.hasOwnProperty(key)) {
      const value = searchObject[key];
      // Check if the value is a string and not empty or contains only whitespace
      if (
        (typeof value === "string" && value.trim() !== "") ||
        typeof value === "boolean"
      ) {
        queryParams.push(`${key}=${encodeURIComponent(value)}`);
      }
    }
  }

  if (queryParams.length > 0) {
    const queryString = queryParams.join("&");
    return `${baseUrl}?${queryString}`;
  } else {
    return baseUrl;
  }
}

export default addSearchItems;
