import React from "react";
import styles from "./Loading.module.css";
import { useTranslation } from "react-i18next";
const Loading = () => {
  const { t } = useTranslation();

  return <div className={styles["loading-bar"]}>{t("Loading")} ...</div>;
};

export default Loading;
