import React from "react";
// import { Redirect } from "react-router";
import { RequireAuth } from "react-auth-kit";
import {
  Route,
  Routes as Switch,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";

import { PAGE_ROUTES } from "./page-routes";

import AppLayout from "../Layout/Layout";
import LoginPage from "../pages/LoginPage/LoginPage";
import NotFoundPage from "../pages/NotFoundPage";

export default function Routes() {
  return (
    <Router>
      <Switch>
        {PAGE_ROUTES.map((onePage) => (
          <Route key="login" element={<AppLayout />}>
            <Route
              key={onePage.id}
              path={onePage.path}
              element={
                <RequireAuth loginPath={"/login"}>
                  {onePage.element}
                </RequireAuth>
              }
            />
          </Route>
        ))}

        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
        <Route key="NotFound" path="/not-found" element={<NotFoundPage />} />
        <Route key="LoginPage" path="/login" element={<LoginPage />} />
      </Switch>
    </Router>
  );
}
