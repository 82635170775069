import React from "react";
import Routers from "./Route/index";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "react-auth-kit";
document.documentElement.style.setProperty("--theme-default", "#ebca39");

const App = () => {
  const queryClient = new QueryClient();
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <AuthProvider
          authType={"cookie"}
          authName={"_auth"}
          cookieDomain={window.location.hostname}
          cookieSecure={window.location.protocol === "https:"}>
          <Routers />
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
};
export default App;
