import { useMutation, useQueryClient } from "react-query";
import { login as loginApi } from "../Services/apiAuth";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import useLocalStorage from "./useLocalStorage";
import Swal from "sweetalert2";
export function useLogin() {
  const { setData } = useLocalStorage();

  const signIn = useSignIn();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate: login, isLoading } = useMutation({
    mutationFn: ({ username, password }) => loginApi({ username, password }),
    onSuccess: (user) => {
      console.log("user", user);
      signIn({
        token: user.data.results.jwt,
        expiresIn: user.data.results.expire_in,
        tokenType: "Bearer",
        authState: { email: user.data.results.data.mail },
      });

      setData("userData", user.data.results.data);
      queryClient.setQueryData(["user"], user);
      navigate("/users", { replace: true });
    },
    onError: (err) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log("ERROR", err);
      //   toast.error('Provided email or password are incorrect');
    },
  });
  return { login, isLoading };
}
