import { Home, UserCheck, Smile, UserPlus } from "react-feather";

const DASHBOARD = [
  {
    path: `/users`,
    icon: UserCheck,
    type: "link",
    title: "Users",
  },
  {
    path: `/purchase-order`,
    icon: Smile,
    type: "link",
    title: "Purchase order",
  },
  {
    path: `/agencies`,
    icon: Home,
    type: "link",
    title: "Agencies",
  },
  {
    path: `/Client`,
    icon: UserPlus,
    type: "link",
    title: "Client",
  },
];
export const MENUITEMS = [
  {
    menutitle: "Distributor",
    menucontent: "Distributor,Widgets",
    Items: DASHBOARD,
  },
];
