// Urls
export const URL_AGENCIES = {
  AGENCIESS_LIST: "/distributor/agencies",
  GET_AGENCIES: "/distributor/agencie",
  GET_AGENCIES_AGENCIES: "/distributor/agencie/",
  CREATE_AGENCIES: "/distributor/agencie",
};

// Columns
export const AGENCIESS_COLUMNS = [
  {
    name: "company",
    selector: "company",
  },
  {
    name: "phone",
    selector: "phone",
  },
  {
    name: "address",
    selector: "address",
  },
  {
    name: "city",
    selector: "city",
  },
  { name: "active", selector: "active", type: "toggleswitch" },
];

// Search
export const AGENCIESS_SEARCH_ITEMS = [
  {
    title: "Company",
    type: "text",
    placeholder: "Company",
    name: "company",
    inputForm: "TitleInput",
  },
  {
    title: "Phone",
    type: "text",
    placeholder: "Phone",
    name: "phone",
    inputForm: "TitleInput",
  },
  {
    title: "Address",
    type: "text",
    placeholder: "Address",
    name: "address",
    inputForm: "TitleInput",
  },
  {
    title: "City",
    type: "text",
    placeholder: "City",
    name: "city",
    inputForm: "TitleInput",
  },
  {
    title: "Zip Code",
    type: "text",
    placeholder: "Zip Code",
    name: "zipCode",
    inputForm: "TitleInput",
  },
];

// Form
export const AGENCIESS_ADD = [
  {
    title: "Company",
    type: "text",
    placeholder: "Company",
    name: "company",
    inputForm: "TitleInput",
  },
  {
    title: "Phone",
    type: "text",
    placeholder: "Phone",
    name: "phone",
    inputForm: "TitleInput",
  },
  {
    title: "Address",
    type: "text",
    placeholder: "Address",
    name: "address",
    inputForm: "TitleInput",
  },
  {
    title: "City",
    type: "text",
    placeholder: "City",
    name: "city",
    inputForm: "TitleInput",
  },
  {
    title: "Zip Code",
    type: "text",
    placeholder: "Zip Code",
    name: "zipCode",
    inputForm: "TitleInput",
  },
];

export const AGENCIESS_EDIT = [
  {
    title: "Company",
    type: "text",
    placeholder: "Company",
    name: "company",
    inputForm: "TitleInput",
  },
  {
    title: "Phone",
    type: "text",
    placeholder: "Phone",
    name: "phone",
    inputForm: "TitleInput",
  },
  {
    title: "Address",
    type: "text",
    placeholder: "Address",
    name: "address",
    inputForm: "TitleInput",
  },
  {
    title: "City",
    type: "text",
    placeholder: "City",
    name: "city",
    inputForm: "TitleInput",
  },
  {
    title: "Zip Code",
    type: "text",
    placeholder: "Zip Code",
    name: "zipCode",
    inputForm: "TitleInput",
  },
];
