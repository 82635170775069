import React from "react";
import { Edit } from "react-feather";

const EditButton = (editElement) => {
  const renderCell = (code) => (
    <button
      className="btn btn-secondary btn-sm"
      color="info"
      onClick={() => editElement(code)}>
      <Edit />
    </button>
  );

  return {
    name: "Edit",
    cell: (row) => renderCell(row),
    center: "true",
  };
};

export default EditButton;
