import axiosClient from "../../Services/axios";
import { addAlert, changedAlert, missingAlert } from "../Alerts/Alerts";

const editData = (inputs, old_data, formItems, getElementUrl, putURL) => {
  let axiosUrl;
  let new_data = {};
  formItems.forEach((item) => {
    const key = item.name;
    if (inputs.hasOwnProperty(key)) {
      new_data[key] = inputs[key];
    } else {
      new_data[key] = old_data[key];
    }
  });
  axiosUrl = putURL ? putURL : getElementUrl;
  console.log("new_data:", new_data);

  axiosClient
    .put(axiosUrl, new_data)
    .then((res) => {
      changedAlert();
    })
    .catch((error) => {
      console.log("new_data=", new_data);
      console.error("Request failed:", error);
      missingAlert();
    });
};
export default editData;
