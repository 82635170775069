import { Fragment, useEffect, useState, useReducer } from "react";
import { Card, CardBody } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
import { useTranslation } from "react-i18next";

import DataTables from "../../Components/DataTables/DataTables";
import Loading from "../../Components/Loading/Loading";
import DataTableHeader from "../../Components/DataTableHeader";
import FilterBar from "../../Components/FilterBar";
import NotFound from "../../Components/NotFound";
import EditItem from "../../Components/EditItem";
import ModalApp from "../../Components/ModalApp";
import AddItem from "../../Components/AddItem";

import { initialState, reducer, fetchRows } from "../../Services/fetchRows";

import {
  URL_PURCHASEORDERS,
  PURCHASEORDERS_COLUMNS,
  PURCHASEORDERS_ADD,
  PURCHASEORDERS_EDIT,
  PURCHASEORDERS_SEARCH_ITEMS,
} from "./data";

import {
  USERS,
  USERS_LIST,
  USERS_PAGE,
  ADD_ITEM,
  EDIT_ITEM,
} from "../../Constant/index";

const PurchaseOrderPage = () => {
  const { t } = useTranslation();
  const [searchLink, setSearchLink] = useState(
    URL_PURCHASEORDERS.GET_PURCHASEORDERS
  );
  const [state, dispatch] = useReducer(reducer, initialState);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [code, setCode] = useState();
  const { rows, isLoading, isError } = state;

  useEffect(() => {
    fetchRows(searchLink, dispatch);
  }, [searchLink]);

  const refreshData = () => {
    fetchRows(URL_PURCHASEORDERS.GET_PURCHASEORDERS, dispatch);
  };

  const optionalFunctions = () => {
    const handleRead = (rowCode) => {
      setCode(rowCode.code);
    };

    const handleEdit = (rowCode) => {
      setCode(rowCode.code);
      EditModaltoggle();
    };

    const handleDelete = (rowCode) => {
      setCode(rowCode.code);
    };

    const changeActiveState = (prevState) => {
      console.log(prevState);
    };

    return { handleRead, handleEdit, handleDelete, changeActiveState };
  };

  const AddModaltoggle = () => setAdd(!add);
  const EditModaltoggle = () => setEdit(!edit);

  if (isError) return <NotFound />;

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs
            parent={t(USERS)}
            title={t(USERS_LIST)}
            mainTitle={t(USERS_PAGE)}
          />
          <FilterBar
            url={URL_PURCHASEORDERS.GET_PURCHASEORDERS}
            searchByItems={PURCHASEORDERS_SEARCH_ITEMS}
            setSearchLink={setSearchLink}
          />
          <DataTableHeader
            AddModaltoggle={AddModaltoggle}
            refreshData={refreshData}
          />
          {isLoading && <Loading />}
          {!isLoading && (
            <DataTables
              new_columns={PURCHASEORDERS_COLUMNS}
              new_rows={rows}
              optionalFunctions={optionalFunctions}
            />
          )}
        </CardBody>
      </Card>

      <ModalApp
        isOpen={add}
        title={t(ADD_ITEM)}
        toggler={AddModaltoggle}
        size="lg">
        <AddItem
          addURL={URL_PURCHASEORDERS.CREATE_PURCHASEORDERS}
          items={PURCHASEORDERS_ADD}
        />
      </ModalApp>

      <ModalApp
        isOpen={edit}
        title={t(EDIT_ITEM)}
        toggler={EditModaltoggle}
        size="lg">
        <EditItem
          getElementUrl={URL_PURCHASEORDERS.GET_PURCHASEORDERS + "/" + code}
          putURL={URL_PURCHASEORDERS.GET_PURCHASEORDERS + "/" + code}
          itemsUpdate={PURCHASEORDERS_EDIT}
        />
      </ModalApp>
    </Fragment>
  );
};
export default PurchaseOrderPage;
