import React, { Fragment } from "react";
import { Card } from "reactstrap";
import { Btn, LI } from "../../../AbstractElements";
import { LogOut } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { useSignOut } from "react-auth-kit";

const LogoutClass = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const handleLogout = () => {
    signOut();
    navigate("/login");
  };

  return (
    <Fragment>
      <LI attrLI={{ className: "onhover-dropdown p-0" }}>
        <Btn
          attrBtn={{
            onClick: handleLogout,
            as: Card.Header,
            className: "btn btn-primary-light",
            color: "default",
          }}>
          <LogOut />
          Log out
        </Btn>
      </LI>
    </Fragment>
  );
};

export default LogoutClass;
