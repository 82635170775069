import { useState, Fragment } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";

import FetchingData from "../../Services/FetchingData";

import { Btn } from "../../AbstractElements";
import Loading from "../Loading/Loading";
import FilterInput from "../Inputs";
import editData from "./editData";

const EditItem = ({ itemsUpdate, getElementUrl, putURL }) => {
  const { data, isLoading, isError } = FetchingData(getElementUrl);
  const [inputs, setInputs] = useState({});
  let old_data = [];

  const handleChange = (event) => {
    if (event.target === undefined) {
      setInputs((values) => ({ ...values, [event.name]: event.value }));
    } else {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const onSubmit = () => {
    editData(inputs, old_data, itemsUpdate, getElementUrl, putURL);
  };

  if (isLoading) return <Loading />;
  else {
    if (isError) return <h1>404 Not Found</h1>;
    old_data = data.data.results.data;

    return (
      <div>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form className="theme-form">
                    <Row>
                      {itemsUpdate.map((item, index) => (
                        <FilterInput
                          key={index}
                          item={item}
                          handleChange={handleChange}
                          index={index}
                          oldValue={old_data[item.name]}
                        />
                      ))}
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          <Btn
                            attrBtn={{
                              onClick: onSubmit,
                              color: "secondary",
                              className: "me-4",
                            }}>
                            Edit
                          </Btn>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

export default EditItem;
