import React, { Fragment, useEffect, useState } from "react";
import { Settings } from "react-feather";
import { Link } from "react-router-dom";
import { H6, Image, LI, UL, P } from "../../AbstractElements";
import useLocalStorage from "../../hooks/useLocalStorage";

const Profile = () => {
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const { getData } = useLocalStorage();
  const userData = getData("userData");
  useEffect(() => {
    setProfile(localStorage.getItem("profileURL"));
    setName(localStorage.getItem("Name"));
  }, [setProfile, setName]);
  return (
    <Fragment>
      <div className="sidebar-user text-center">
        <div className="setting-primary">
          <Settings />
        </div>
        {/* <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}> */}
        <Image
          attrImage={{
            className: "img-90 rounded-circle",
            src: userData ? userData.img : "",
            //   src: authenticated ? auth0_profile.picture : profile,
            alt: "",
          }}
        />
        <div className="badge-bottom">
          <div className="badge badge-primary">New</div>
        </div>
        <H6
          attrH6={{
            className: "mt-3 f-14 f-w-600",
          }}>{`${userData.firstname} ${userData.lastname}`}</H6>
        {/* </Link> */}
      </div>
    </Fragment>
  );
};

export default Profile;
